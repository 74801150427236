import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { QuestionWithAnswerResponse } from '@app/model/questionWithAnswerResponse';
import { SelectionbyPathResponse } from '@app/model/selectionbyPathResponse';
import { Summary } from "@app/model/Summary";
import { ReferenceLink } from "@app/model/ReferenceLink";
import { Observable } from 'rxjs';
import { environment } from '@environments/environments'
import { protectedResources } from '@app/authentication/auth-config';
import { NextStepAdditionalInfo } from '@app/model/NextStepAdditionalInfo';
import  { GroupGuard} from '../authentication/group.guard'
import { QuestionAnswersList } from '@app/model/enumerations';
import { CategoryList } from '@app/model/CategoryList';
import { AllQuestions } from '@app/model/AllQuestionsData';
import { AllReferenceLinks } from '@app/model/AllReferenceLinksData';
import { AllAnswers } from '@app/model/AllAnswersData';
import { Solution } from '@app/model/Solution';
import { Selection } from '@app/model/Selections';

const baseUrl = `${environment.apiUrl}`;


@Injectable({
  providedIn: 'root'
})
export class CcdService {

  constructor(private _http: HttpClient , private  group:GroupGuard) { }  

  getQuestionWithAnswers(questionAnswerList: QuestionAnswersList): Observable<QuestionWithAnswerResponse[]> {

    if(questionAnswerList == QuestionAnswersList.All) {
      return this._http.get<QuestionWithAnswerResponse[]>(protectedResources.ccdAdminQuestionApi.endpoint);

    } else {

      return this._http.get<QuestionWithAnswerResponse[]>(protectedResources.ccdUserQuestionApi.endpoint);
    }
  }

  getAllQuestions(): Observable<AllQuestions[]>{
    return this._http.get<AllQuestions[]>(protectedResources.ccdGetAllQuestions.endpoint);
  }
  getAllAnswers(): Observable<AllAnswers[]>{
    return this._http.get<AllAnswers[]>(protectedResources.ccdGetAllAnswers.endpoint);
  }
  //category API's
  getCategoriesList(): Observable<CategoryList[]>{
      return this._http.get<CategoryList[]>(protectedResources.ccdGetCategoryListApi.endpoint);
  }
  
  getAllReferenceLinks(): Observable<AllReferenceLinks[]>{
    return this._http.get<AllReferenceLinks[]>(protectedResources.ccdReferencelinksApi.endpoint + '/all');
  }

  addNewCategory(data:any): Observable<any>{
    return this._http.post<any>(protectedResources.ccdCategoryListApi.endpoint, data);
  }
  updateCategory(id:number,data: any): Observable<any>{
    
    return this._http.put(`${protectedResources.ccdPutCategoryListApi.endpoint}/${id}`,data);
  }

  deleteCategory(id:number): Observable<any> {
    return this._http.delete(`${protectedResources.ccdDeleteCategoryListApi.endpoint}${id}`);
  }
  updateQuestion(id:number,question: AllQuestions): Observable<any>{
    
    return this._http.put(`${protectedResources.ccdPutQuestionApi.endpoint}/${id}`,question);
  }

  deleteQuestion(id:number): Observable<any> {
    return this._http.delete(`${protectedResources.ccdDeleteQuestionApi.endpoint}${id}`);
  }
  updateAnswer(id:number,answer: AllAnswers): Observable<any>{
    
    return this._http.put(`${protectedResources.ccdPutAnswerApi.endpoint}/${id}`,answer);
  }

  deleteAnswer(id:number): Observable<any> {
    return this._http.delete(`${protectedResources.ccdDeleteAnswerApi.endpoint}${id}`);
  }
  getAnswersByPath(path: string): Observable<SelectionbyPathResponse[]> {

    return this._http.get<SelectionbyPathResponse[]>(protectedResources.ccdSelectionPathApi.endpoint + path + '/answers');
  }

  getAllSolutionsData(): Observable<Solution[]> {

    return this._http.get<Solution[]>(protectedResources.ccdGetAllSolutionApi.endpoint);
  }

  getSoutionBySolutinId(id: number): Observable<Summary> {

    return this._http.get<Summary>(protectedResources.ccdSolutionApi.endpoint + id + '/relationshipItemsOnly');
  }


  getReferenceLinkBySolutinId(id: number): Observable<ReferenceLink[]> {

    return this._http.get<ReferenceLink[]>(protectedResources.ccdRefrencelinkApi.endpoint + id);
  }

  getNextStepBySolutinId(id: number): Observable<NextStepAdditionalInfo[]> {
    
    return this._http.get<NextStepAdditionalInfo[]>(protectedResources.ccdNextStepAdditionalInfoApi.endpoint + id);
  }

  addReferenceLink(data: ReferenceLink): Observable<any> {
    return this._http.post(`${baseUrl}/referenceLinks`, data, { observe: 'response' });
  }

  updateReferenceLink(id: number, data: ReferenceLink): Observable<any> {
    return this._http.put(`${baseUrl}/referenceLinks/${id}`, data, { observe: 'response' });
  }


  deleteReferenceLink(id: number): Observable<any> {
    return this._http.delete(`${baseUrl}/referenceLinks?id=` + id);
  }  


  updateSolution(data: Summary): Observable<Summary> {
    const httpOptions = {
      headers: new HttpHeaders({'Access-Control-Allow-Origin': '*'})
    }
     return this._http.put<Summary>(protectedResources.ccdSolutionApiPut.endpoint + data.id +'/includeRelationship', data, httpOptions);
   
  }

  addSolutionsOnlyApi(data: FormData): Observable<Solution>{
    const httpOptions = {
      headers: new HttpHeaders({'Access-Control-Allow-Origin': '*'})
    }
    return this._http.post<Solution>(protectedResources.ccdSolutionPostApi.endpoint, data,httpOptions);
  }

  updateSolutionsWithImage(data: FormData): Observable<Solution>{
    const id = Number(data.get('id'));
    const httpOptions = {
      headers: new HttpHeaders({'Access-Control-Allow-Origin': '*'})
    }
    return this._http.put<Solution>(`${protectedResources.ccdSolutionPutApiWithImage.endpoint}${id}`, data,httpOptions);
  }

  deleteSolutionAPI(id:number): Observable<any> {
    return this._http.delete(`${protectedResources.ccdSolutionDeleteApi.endpoint}/${id}`);
  }

  getAllSelectionsData(): Observable<Selection[]>{
    return this._http.get<Selection[]>(`${protectedResources.ccdSelectionListApi.endpoint}`)
  }

  mapSolutionPostData(data:Selection): Observable<Selection>{
    return this._http.post<Selection>(protectedResources.ccdPostSelectionApi.endpoint, data);
  }

  mapDeleteSolution(selectionID : Number): Observable<any>{
    return this._http.delete<any>(`${protectedResources.ccdDeleteSelectionApi.endpoint}/${selectionID}`);
  }

  getAnswerByCategoriesId(categoryIds: string[]): Observable<[]>{
    return this._http.get<[]>(`${protectedResources.ccdAnswerForCategoryApi.endpoint}${categoryIds}`);
  }

  getAnswerBySearch(questionId:any,categoryIds: string[]): Observable<[]>{
    return this._http.get<[]>(`${protectedResources.ccdAnswerForQuestionAndCategoryApi.endpoint}?questionId=${questionId}&categoryIds=${categoryIds}`);
  }

  getSolutionByAnswerId(answerId: any): Observable<any>{
    return this._http.get<any>(`${protectedResources.ccdSolutionByAnswerApi.endpoint}${answerId}/byAnswer`);
  }

  getMappedCategories(): Observable<any>{
    return this._http.get<any>(`${protectedResources.ccdMappedCategoriesApi.endpoint}`);
  }

}
