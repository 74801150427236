import { Injectable } from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarHorizontalPosition,
  MatSnackBarRef,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { environment } from '@environments/environments';
import { GlobalConstants } from '@app/constants/global-constants';

const ACTION_SUCCESS: string = "success";
const ACTION_ERROR: string = "error";

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  snackBarConfig: MatSnackBarConfig;
  snackBarRef: MatSnackBarRef<any>;
  sbHorizontalPosition: MatSnackBarHorizontalPosition = 'center';
  sbVerticalPosition: MatSnackBarVerticalPosition = 'top';
  sbAutoHideTime = '5800'; 

 constructor(private snackBar: MatSnackBar,
   private globalConstants: GlobalConstants
   ) { }
 
 sbOpenSuccess(message: string, showActualMsg?: boolean )
 {
     const msg = environment.showGenericErrMsg && showActualMsg == undefined? this.globalConstants.waitSuccessMsg: message; 
     this.openSnackBar(ACTION_SUCCESS,msg);
 }

 sbOpenError(sbMessage :string, showActualMsg?: boolean)
 {
   const msg = environment.showGenericErrMsg && showActualMsg == undefined? this.globalConstants.waitFailureMsg : sbMessage; 
   this.openSnackBar(ACTION_ERROR, msg);
 }

 sbDataFetchingError(sbMessage :string, showActualMsg?: boolean){
  const msg = environment.showGenericErrMsg && showActualMsg == undefined? this.globalConstants.noAnswersMsg : sbMessage; 
  this.openSnackBar(ACTION_ERROR, msg);
 }

 private openSnackBar(action_type :string, sbMessage :string) {
   this.snackBarConfig = new MatSnackBarConfig();
   this.snackBarConfig.duration = parseInt(this.sbAutoHideTime);
   this.snackBarConfig.horizontalPosition = this.sbHorizontalPosition;
   this.snackBarConfig.verticalPosition = this.sbVerticalPosition;
   this.snackBarConfig.panelClass = action_type==ACTION_SUCCESS ? ['success-snackbar'] : ['error-snackbar'];
   this.snackBarRef = this.snackBar.open(sbMessage, '', this.snackBarConfig);
}
}
