<footer id="dnnfooter">
  <div class="dnnrow">
    <div class="panebox innermargin">
      <div id="side-box" style="display: none;">
        <a href="#top" class="totop" title="Back Top"><i class="icon-up-open whitecolor i16"></i></a>
      </div>
      <div class="fleft Grid9LPane">
        <div class="fleft Grid3LPane">
          <span class="footerMainLink">Quick Links</span>
          <br>
          <a href="https://dev.azure.com/HAL-HDS/iEnergy/_git/terraform-quickstart-template" target="_blank"  class="footerSubLink">iEnergy QuickStart Application Template</a>
          <br>
          <a href="https://dev.azure.com/HAL-HDS/iEnergy/_git/app-quickstart-template-static-web-api-sql" target="_blank"  class="footerSubLink">iEnergy QuickStart Infrascructure Template</a>
        </div>
        <div class="Grid6RPane">
          <div class="fleft Grid3LPane">
            <span class="footerMainLink">Information</span>
            <br>
            <a href="http://www.halliburton.com/en-US/about-us/default.page" target="_blank" class="footerSubLink">About Halliburton</a>
            
          </div>
          <div class="fleft Grid3LPane">
            <span class="footerMainLink">Careers</span>
            <br>
            <a href="https://jobs.halliburton.com/?node-id=hgbr8q6n" target="_blank"  class="footerSubLink">Job Openings</a>
            <br>
            <a href="https://jobs.halliburton.com/content/students-and-recent-graduates/" target="_blank" class="footerSubLink">Students</a>
          </div>
        </div>
      </div>
      <div class="fright Grid3RPane">
        <div class="innermargin">
          <div class="panebox">
            <div class="fright">
              <div class="fontsocial light" style="padding-top:10px">
                <a href="https://www.facebook.com/halliburton" target="_blank"><img style="margin-bottom:5px" src="../../../../assets/images/footer/footer-fbicon.png"></a>
                <a href="https://twitter.com/halliburton" target="_blank"><img style="margin-bottom:5px" src="../../../../assets/images/footer/footer-twittericon.png"></a>
                <a href="https://plus.google.com/+halliburton/posts" target="_blank"><img style="margin-bottom:5px" src="../../../../assets/images/footer/footer-googleplusicon.png"></a>
                <a href="https://www.youtube.com/user/Halliburton" target="_blank"><img style="margin-bottom:5px" src="../../../../assets/images/footer/footer-youtubeicon.png"></a>
                <a href="http://halliburtonblog.com/" target="_blank"><img style="margin-bottom:5px" src="../../../../assets/images/footer/footer-bloggericon.png"></a>
                <a href="https://www.linkedin.com/company/e-p-software" target="_blank"><img style="margin-bottom:5px" src="../../../../assets/images/footer/footer-inicon.png"></a>
                <a href="http://www.halliburton.com/en-US/rss-news-feeds.page" target="_blank"><img style="margin-bottom:5px" src="../../../../assets/images/footer/footer-rssicon.png"></a>

              </div>
              <div style="padding-top:25px">
                <img src="../../../../assets/images/footer/ienergy_LEFT_WHITE.png" alt="iEnergy QuickStart Template">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="fleft copyright">
        <span id="dnn_dnnCopyright_lblCopyright" class="copyright">Copyright © {{currentYear}} Halliburton. All Rights Reserved.</span>

        <a id="dnn_dnnTerms_hypTerms" class="links" rel="nofollow" target="_blank"  href="https://www.ienergy.community/Portals/1/IenergyDocs/Terms_and_Conditions.pdf"
          target="_blank">Terms Of Use</a> |
        <a id="dnn_dnnPrivacy_hypPrivacy" class="links" rel="nofollow" target="_blank"  href="http://www.halliburton.com/en-US/about-us/legal/privacy-policy.page"
          target="_blank">Privacy Statement</a>
        <a href="http://www.halliburton.com/" target="_blank" class="footerSubLink" style="padding-left:10px">Go to Halliburton</a>
      </div>

    </div>
  </div>

</footer>