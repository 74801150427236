import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from '@core/navigation/home/home.component';
import { OverageComponent } from './overage/overage.component';
import { BaseGuard } from './authentication/base.guard';
import { GroupGuard } from './authentication/group.guard';
import { groups } from './authentication/auth-config';
import { BrowserUtils } from '@azure/msal-browser';
import { MsalRedirectComponent } from '@azure/msal-angular';


const siteModule = () => import('@site/site.module').then(x => x.SiteModule);

const routes: Routes = [
    {
        path: '', component: HomeComponent,
        canActivate: [GroupGuard],
        data: {
            requiredGroups: [
                { user: "user" }, { admin: "admin" }]
        }},
    {
        path: 'site', loadChildren: siteModule , canActivate: [GroupGuard],
        data: {
            requiredGroups: [
                { user: "user" }, { admin: "admin" }]
        }},
    {
        path: 'overage',
        component: OverageComponent,
        canActivate: [
            BaseGuard,
        ]
    },
    {
        path: 'auth',
        component: MsalRedirectComponent
    }
];

@NgModule({

    imports: [RouterModule.forRoot(routes,
        {
            // Don't perform initial navigation in iframes or popups
            useHash: true,
            initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledNonBlocking' : 'disabled'

        })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
