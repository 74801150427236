export const environment = {
  production: false,
  apiUrl: 'https://dev.qsat-poc-api.ienergy.halliburton.com', //'https://dev.ccd-api.ienergy.halliburton.com' //'https://localhost:7211',
  showGenericErrMsg: true,
  auth: {
    clientId: 'f643d28f-4706-499c-afee-b88f007d50bc', // This is the ONLY mandatory field that you need to supply.
    authority: 'https://login.microsoftonline.com/b7be7686-6f97-4db7-9081-a23cf09a96b5', // Replace the placeholder with your tenant subdomain https://login.microsoftonline.com/b7be7686-6f97-4db7-9081-a23cf09a96b5
    redirectUri: 'https://dev.qsat-poc.ienergy.halliburton.com', //'https://dev.customchemistry.ienergy.halliburton.com/',  // http://localhost:4200/' Points to window.location.origin by default. You must register this URI on Azure portal/App Registration.
    postLogoutRedirectUri: '/', // Points to window.location.origin by default
  },
  scopes: {
    read: ["api://9d69d1b4-77f8-494d-a3d4-cd1089e82a48/QSAT.ReadWrite.All"]
  },
  appInsights: {
    instrumentationKey: '8fae4280-668e-4cb9-aaa4-fff35cc92ac0'
  },
  groups: {
    groupAdmin: "1e9b173e-2edb-41eb-a395-6aa6ea575134",
    groupMember: "75fc7237-dd84-4611-b6cc-64dd545c7f57"
  },

  secure: false
};