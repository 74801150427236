
import { LogLevel, Configuration, BrowserCacheLocation } from '@azure/msal-browser';
import {environment} from '@environments/environments'

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;
const baseUrl = `${environment.apiUrl}`;

const apiScopes= environment.scopes;

/**
 * Configuration object to be passed to MSAL instance on creation.
 */
export const msalConfig: Configuration = {
    auth: environment.auth,

    cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: isIE, // Set this to "true" if you are having issues on IE11 or Edge. Remove this line to use Angular Universal
    },
    system: {
        /**
         * Below you can configure MSAL.js logs for troubleshoot:
        
         */
        loggerOptions: {
            loggerCallback(logLevel: LogLevel, message: string) {
               // console.log(message);
            },
            logLevel: LogLevel.Verbose,
            piiLoggingEnabled: false
        }
    }
}

/**
 * Add here the endpoints and scopes when obtaining an access token for protected web APIs. For more information, see:
 */
export const protectedResources = {
    ccdUserQuestionApi: {
        endpoint: `${baseUrl}/api/Questions/all/withAnswers`,
        scopes:apiScopes
    },
    ccdGetCategoryListApi:{
        endpoint: `${baseUrl}/api/Categories/all`,
        scopes: apiScopes
    },
    ccdCategoryListApi:{
        endpoint: `${baseUrl}/api/Categories`,
        scopes: apiScopes
    },
    // ccdPostCategoryListApi:{
    //     endpoint: `${baseUrl}/api/Categories`,
    //     scopes: apiScopes
    // },
    ccdPutCategoryListApi:{
        endpoint: `${baseUrl}/api/Categories`,
        scopes: apiScopes
    },
    ccdDeleteCategoryListApi:{
        endpoint: `${baseUrl}/api/Categories/`,
        scopes: apiScopes
    },
    ccdGetAllQuestions:{
        endpoint: `${baseUrl}/api/Questions/all`,
        scopes: apiScopes
    },
    // ccdPostQuestionApi:{
    //     endpoint: `${baseUrl}/api/Questions`,
    //     scopes: apiScopes
    // },
    ccdPutQuestionApi:{
        endpoint: `${baseUrl}/api/Questions`,
        scopes: apiScopes
    },
    ccdDeleteQuestionApi:{
        endpoint: `${baseUrl}/api/Questions/`,
        scopes: apiScopes
    },
    ccdGetAllAnswers:{
        endpoint: `${baseUrl}/api/Answers/all`,
        scopes: apiScopes
    },
    ccdMappedCategoriesApi:{
        endpoint: `${baseUrl}/api/Categories/mapped/all`,
        scopes: apiScopes
    },
    ccdPutAnswerApi:{
        endpoint: `${baseUrl}/api/Answers`,
        scopes: apiScopes
    },
    ccdDeleteAnswerApi:{
        endpoint: `${baseUrl}/api/Answers/`,
        
        scopes: apiScopes
    },
    ccdAdminQuestionApi: {
        endpoint: `${baseUrl}/api/Questions/withAnswers`,
        scopes: apiScopes
    },
    ccdSelectionPathApi: {
        endpoint: `${baseUrl}/api/Selections/`,
        scopes: apiScopes
    },
    ccdGetAllSolutionApi: {
        endpoint: `${baseUrl}/api/Solutions/all`,
        scopes: apiScopes
    },
    ccdSolutionApi: {
        endpoint: `${baseUrl}/api/Solutions/`,
        scopes: apiScopes
    },
    ccdSolutionApiPut: {
        endpoint: `${baseUrl}/api/Solutions/update/`,
        scopes: apiScopes
    },
    ccdSolutionPostApi:{
        endpoint: `${baseUrl}/api/Solutions/SolutionOnly`,
        scopes: apiScopes
    },
    ccdSolutionPutApiWithImage:{
        endpoint: `${baseUrl}/api/Solutions/Solution/`,
        scopes: apiScopes
    },
    ccdSolutionDeleteApi: {
        endpoint: `${baseUrl}/api/Solutions`,
        scopes: apiScopes
    },
    ccdRefrencelinkApi: {
        endpoint: `${baseUrl}/api/ReferenceLink/`,
        scopes: apiScopes
    },
    ccdReferencelinksApi: {
        endpoint: `${baseUrl}/api/ReferenceLinks`,
        scopes: apiScopes
    },
    ccdNextStepAdditionalInfoApi: {
        endpoint: `${baseUrl}/api/NextStepAdditionalInfo/`,
        scopes: apiScopes
    },
    ccdSelectionListApi:{
        endpoint: `${baseUrl}/api/Selections/all`,
        scopes: apiScopes
    },
    ccdPostSelectionApi:{
        endpoint: `${baseUrl}/api/Selections/Add`,
        scopes: apiScopes
    },
    ccdDeleteSelectionApi:{
        endpoint: `${baseUrl}/api/Selections`,
        scopes: apiScopes
    },
    ccdAnswerForCategoryApi:{
        endpoint: `${baseUrl}/api/Answers/search?categoryIds=`,
        scopes: apiScopes
    },
    ccdAnswerForQuestionAndCategoryApi:{
        endpoint: `${baseUrl}/api/Answers/search`,
        scopes: apiScopes
    },
    ccdSolutionByAnswerApi:{
        endpoint: `${baseUrl}/api/Selections/`,
        scopes: apiScopes
    },
    apiGraph: {
        endpoint: "https://graph.microsoft.com/v1.0/me/checkMemberGroups",
        scopes: ["User.Read", "GroupMember.Read.All"]
    }
}

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 */
export const loginRequest = {
    scopes: [...protectedResources.ccdAdminQuestionApi.scopes.read]
};

export const groups = environment.groups;

/**
 * IMPORTANT: In case of overage, group list is cached for 1 hr by default, and thus cached groups 
 * will miss any changes to a users group membership for this duration. For capturing real-time 
 
 */
export const CACHE_TTL_IN_MS = 60 * 60 * 1000; // 1 hour in milliseconds