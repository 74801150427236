<div class="container headerHeight" fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="0.5%"
  fxLayoutAlign="center"
  style='background: url("../../../../assets/images/footer/Header2.png"); background-size: cover; '>
  <div fxLayout  fxFlex="90" class="flex">
    <div>
      <div class="overlaytext">QuickStart Application Template</div>
      <div class="overlaytextTagLine">Angular Frontend , .NET Core API & SQL Database</div>
    </div>
  </div>
  <div  fxLayout="row" fxFlex="15" class="flex">
    <a matTooltip='User' matTooltipClass="custom-tooltip-primary" mat-list-item
      routerLink='/site/ccd-user'>
      <mat-icon class="user-icon">person</mat-icon>
    </a>
    <a matTooltip='Admin' matTooltipClass="custom-tooltip-primary" *ngIf="isAdmin()" mat-list-item
      routerLink='/site/ccd-admin'>
      <mat-icon class="hamburger">create</mat-icon>
    </a>
    <a matTooltip='Contact' matTooltipClass="custom-tooltip-primary" mat-list-item
      href='mailto:DL_HDS_iEnergy_PlatAdmin@halliburton.com'>
      <mat-icon matTooltipClass="custom-tooltip-primary" class="hamburger">live_help</mat-icon>
    </a>
  </div>
</div>