import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})

export class GlobalConstants {

    public readonly waitSuccessMsg = 'Request successful. Please wait ...'
    public readonly waitFailureMsg = 'Error while processing the request. Please wait ...'
    public readonly noAnswersMsg = 'No Problems available for above selections'
    public readonly msgTimeOut = 20000;
    public readonly setTimeOutAfterSubscription = 20000;
    public  selected = -1;
    public  IsAdmin:boolean=false;

}