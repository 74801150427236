<div style="margin-top: 0px; ">
  <mat-card-title class="mat-card-title">Please confirm</mat-card-title>
  <div mat-dialog-content>
    <p style="margin-left:10px; padding: 0px;white-space: pre-wrap;">{{confirmMSg}}</p>
  </div>
  <div mat-dialog-actions align="center">
    <button mat-raised-button type="button" class="button-style cancel-button" (click)="onNo()"
    >NO</button>
    <button mat-raised-button type="button" class=" btnSmall" color="warn" (click)="onYes()">YES</button>
  </div>
</div>